import { graphql, useStaticQuery } from 'gatsby';

export const useGlobalSite = () => {
    const  {contentfulDigitupGlobalSite2}  = useStaticQuery(graphql`
        query {
            contentfulDigitupGlobalSite2 {
                name
                machinePageText
                machinePagePdf {
                file {
                    url
                    fileName
                }
    }
            }
        }
    `);
    
    return contentfulDigitupGlobalSite2
};

