import React from 'react'
import * as styles from './parallax-image.module.scss'
import { Parallax } from "react-scroll-parallax"

const ParallaxImage = ({
    children,
    extraClass = '',
    id = '',
    type = 'scale'
}: {
    children: any,
    extraClass?: string,
    id?: string,
    type?: "scale" | "size" | 'speed'
}) => {
    switch (type) {
        case "size":
        case 'speed':
            return (
                <div id={id} className={`${extraClass} ${styles.imageContainer}`}>
                    <Parallax
                        speed={-3}
                        // scale={[0.9, 1.1]}
                        easing="easeInOutCubic"
                    >
                        {children}
                    </Parallax>
                </div>
            )
        case "scale":
        default:
            return (
                <div id={id} className={`${extraClass} ${styles.imageContainer}`}>
                    <Parallax
                        // speed={-8}
                        scale={[0.9, 1.1]}
                        easing="easeInOutCubic"
                    >
                        {children}
                    </Parallax>
                </div>
            )
    }
}

export default ParallaxImage
